import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Icon from '../../../components/Icon/Icon.component';
import styles from './SidebarProfile.module.css';
import { useEffect, useRef, useState } from 'react';
import useStateSelector from '../../../store/hooks/useStateSelector.hook';
import { AuthSliceType } from '../../../store/slices/auth/auth.slice';
import useActionsSelector from '../../../store/hooks/useActionsSelector.hook';
import useActionsDispatcher from '../../../store/hooks/useActionsDispatcher.hook';
import { AccountMSTypes } from '../../../types/definitions';
import AccountMS from '../../../services/ms/AccountMS.service';
import Cookies from 'js-cookie';

type SidebarProfileProps = {
  isCollapsed: boolean;
  user?: AccountMSTypes.ById | undefined;
  setUser?: (user: AccountMSTypes.ById) => void;
};

function SidebarProfile(props: SidebarProfileProps) {
  const { isCollapsed, user, setUser } = props;

  const { value }: AuthSliceType = useStateSelector('auth');
  const dispatch = useActionsDispatcher();

  const location = useLocation();
  const { clear } = useActionsSelector('auth');

  const [isPopUpVisible, setPopUpVisibility] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);

  function togglePopup(): void {
    setPopUpVisibility((prev) => !prev);
  }

  function handleOutsideClick(e: MouseEvent): void {
    if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
      setPopUpVisibility(false);
    }
  }

  useEffect(() => {
    async function fetchUser() {
      if (!value.user) return;

      const response = await AccountMS.ById(value.user.id);

      setTimeout(() => {
        if (response === false) {
          Cookies.remove(process.env.REACT_APP_COOKIE_KEY_TOKEN!);
          dispatch(clear());
        }
      }, 100);

      if (!response) return;

      if (setUser) {
        setUser(response);
      }
    }

    fetchUser();

    setPopUpVisibility(false);
    document.addEventListener('mousedown', (e: MouseEvent) =>
      handleOutsideClick(e)
    );
    return () => {
      document.removeEventListener('mousedown', (e: MouseEvent) =>
        handleOutsideClick(e)
      );
    };
  }, [value.user, location.pathname]);

  return (
    <section
      data-testid="sidebar-profile"
      ref={popupRef}
      className={styles.container}
    >
      <button
        data-testid="toggle-popup-button"
        data-collapsed={isCollapsed}
        className={styles.profile}
        onClick={togglePopup}
      >
        <span className={styles.photo}>
          {user?.photo && <img src={user?.photo || ''} alt="Foto de perfil" />}
        </span>
        {!isCollapsed && (
          <>
            <div className={styles.data}>
              <p>{user?.name}</p>
              {/* <small>{value.user?.active.company_name}</small> */}
            </div>
            <Icon className={styles.icon} name="Draggable" size={16} />
          </>
        )}
      </button>
      {isPopUpVisible && (
        <div data-testid="profile-popup" className={styles.popup}>
          <div className={styles.popupUpper}>
            <div className={styles.popupProfile}>
              <span className={styles.photo}>
                {user?.photo && (
                  <img src={user?.photo || ''} alt="Foto de perfil" />
                )}
              </span>
              <div className={styles.data}>
                <p>{user?.name}</p>
                {/* <small>{value.user?.active.company_name}</small> */}
              </div>
            </div>
            <hr />
            <Link to={'/settings'}>
              <Icon name="Gear" />
              Configurações
            </Link>
            <button onClick={() => dispatch(clear())}>
              <Icon name="DoorClosed" />
              Sair
            </button>
          </div>
          {/* <div className={styles.popupBottom}>
                    <div className={styles.switcherWrapper} data-theme={"light"}>
                        <button name="light-switch" role="switch" aria-checked="true">
                            <Icon name="Sun" size={20} />
                            Claro
                        </button>
                        <button name="dark-switch" role="switch" aria-checked="false">
                            <Icon name="Moon" size={20} />
                            Escuro
                        </button>
                    </div>
                </div> */}
        </div>
      )}
    </section>
  );
}

export default SidebarProfile;
